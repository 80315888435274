import { Controller } from "@hotwired/stimulus"
import { useDebounce } from "stimulus-use"

export default class extends Controller {
  static debounces = ['onSubmit']
  static values = {
    triggerEvent: String
  }

  initialize() {
    useDebounce(this)
    this.initialValue = this.element.value
    this.targetElement = this.element

    if (this.element.inputElement) {
      // is rich text element / <trix-editor>
      this.targetElement = this.element.inputElement
    }
    this.element.addEventListener(this.triggerEventValue, this.onTriggerEvent.bind(this))
    this.targetElement.form.addEventListener("turbo:submit-end", this.onSubmitEnd.bind(this))

    if (this.element.dataset.autosaveInputIncludeOtherFormTarget) {
      this.includeOtherFormTarget = this.element.dataset.autosaveInputIncludeOtherFormTarget
    }
  }

  onTriggerEvent(e) {
    if (this.initialValue != this.element.value) {
      this.onSubmit(e)
    }
  }

  // Helper function to copy values from one form element to another
  copyInputValues(srcElement, destForm) {
    const newInput = document.createElement('input');
    newInput.type = 'hidden';
    newInput.name = srcElement.name;
    newInput.value = srcElement.value;
    destForm.appendChild(newInput);
  }

  onSubmit(e) {
    if (this.includeOtherFormTarget) {
      const otherForm = document.querySelector(`[data-autosave-input-form-target='${this.includeOtherFormTarget}']`);

      // If otherForm exists, we'll get all inputs, select, and textarea elements and copy their values to the target form
      if (otherForm) {
        const currentForm = this.targetElement.form;
        const otherFormElements = otherForm.querySelectorAll('input, select, textarea');

        otherFormElements.forEach((element) => {
          switch (element.tagName.toLowerCase()) {
            case 'input':
            case 'textarea':
              this.copyInputValues(element, currentForm);
              break;
            case 'select':
              if (element.selectedOptions.length > 0) {
                const newInput = document.createElement('input');
                newInput.type = 'hidden';
                newInput.name = element.name;
                newInput.value = element.selectedOptions[0].value;
                currentForm.appendChild(newInput);
              }
              break;
          }
        });
      }
    }

    this.targetElement.form.requestSubmit()
  }

  onSubmitEnd(e) {
    // do something on end
  }

}